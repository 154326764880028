.secondary-cursor {
  z-index: 1000;
  border-radius: 50%;
  pointer-events: none;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  position: fixed;
  background: white;
  // border: 1px solid black;
  mix-blend-mode: difference;
  transition: width 0.25s ease-in-out, height 0.25s ease-in-out;

  &.regular {
    width: 20px;
    height: 20px;
  }
  &.small {
    width: 40px;
    height: 40px;
  }
}

// .hero{
 


//     background-color: aqua;
// }

.hero_container{
 //padding-top:70px ;
  width: 100%;
  background-color: aqua
  
  
  //min-height: 50vh;

  ;
  
}

.hero{
  
  height: auto;
min-height: 100vh;
position: relative;
 
 overflow: hidden;
  
}




#a{
  height: 100vh;
  position: relative;

  overflow:hidden;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  
}

.topRel{

  @media (max-width:600px){
   
  }

}



.arrow_slide{
 position: absolute;
 top: 55%;
 cursor: pointer;
 z-index: 1;

 @media (max-width:600px){
  top: 75%;
 }
}


.left_arrow_slide{

  left: 5%;
  svg{
 
    width: 100px;
    height: 30px;
    path{
      fill: #f00;
    }
  }

}

.right_arrow_slide{
  right: 5%;
  svg{
    width: 100px;
    height: 30px;
    path{
      fill: #f00;
    }
  }
  
}


.footer__text{

  min-width: 230px;
 // text-align: center;
  flex-grow: 1;
  padding: 20px;
}


.black-linear{


  // make liner black bottom to top use hex color code think twice 





  
  


  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;


}


.video_container{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  grid-gap: 20px;
  padding: 20px;

 
  

  
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;

  @media (max-width:450px){
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }


  // overflow: hidden;
}





// .video_child > div video {
 
//     position: relative;
//     left: 0;
//     top: 0;
//     transform: none;

// }



.dwam{
  width: 20%;
  //background-color: blue;

  @media (max-width:600px){
    width:80%;
    margin-top: 10px;
  }
  
}


.secondary-cursor {
  z-index: 10000;

  pointer-events: none;
  // overflow: hidden;
  transform: translate3d(0, 0, 0);
  position: fixed;
}


.secondary-cursor {
  width: 20px;
  height: 20px;

}

.slider-drag {


 
}

.hamburger {
  

}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bgUp {
  from {
    background-color: transparent;
  }

  to {
    background-color: black;
  }
}

@keyframes bgDown {
  from {
    background-color: black;
  }

  to {
    background-color: transparent;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.5);
  }
}

@keyframes scaleDown {
  from {
    transform: scale(1.5);
  }

  to {
    transform: scale(1);
  }
}

@keyframes translateLeftDot {
  from {
    transform: translate(20px, -50%);
  }

  to {
    transform: translate(0px, -50%);
  }
}

@keyframes translateRightDot {
  from {
    transform: translate(-20px, -50%);
  }

  to {
    transform: translate(0px, -50%);
  }
}