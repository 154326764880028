
@font-face {
  font-family: "Satoshi-Medium";
  src: local("Satoshi-Medium"),
   url("../../styles/Fonts/Satoshi-Medium.otf") format("truetype");
  font-weight: 600;
  }


  @font-face {
    font-family: "Satoshi-Regular";
    src: local("Satoshi-Regular"),
     url("../../styles/Fonts/Satoshi-Regular.otf") format("truetype");
    font-weight: 600;
    }

    
    @font-face {
      font-family: "Satoshi-Light";
      src: local("Satoshi-Light"),
       url("../../styles/Fonts/Satoshi-Light.otf") format("truetype");
      font-weight: 600;
      }

      @font-face {
        font-family: "Satoshi-Bold";
        src: local("Satoshi-Bold"),
         url("../../styles/Fonts/Satoshi-Bold.otf") format("truetype");
        font-weight: 600;
        }
      
    
  