.header{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 85px;
    background-color: $background;
    position: fixed;
    width: 100%;
    
    
    z-index: 1000;
    transition: .3s all ease-in;
 

    div{
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

 

    .logo{
     //   background-color: aqua;

     .inner_logo{
        width: 50%;
        height: 100%;
        margin-top: 10px;
  
        align-items: center;
        
        display: flex;
        margin-right: auto;
     }

    }

    .link{
    //    background-color: red;
   
        height: 50%;
        .inner_link{
            width: 84%;
            justify-content: space-evenly;
            align-items: flex-end;
                
          
            height: 100%;


            
    @media screen and (max-width:950px){
        width: 940%;
        // background-color: red;
        justify-content: space-between;
      
    }

        }


    }

    .lang{
    //    background-color: black;

    .inner_lang{
        width: 60%;
      
        height: 35%;
        align-items: flex-end;
        
        display: flex;
        margin-left: auto;

        .language_list{
            display: flex;
            width: 70%;
            justify-content: space-between;
            align-items: flex-end;


            
    @media screen and (max-width:900px){
       width: 90%;
    }

       

           
            
           
        }

     }


    }


    @media screen and (max-width:600px){
        display: none;
    }


}

.mobile_nav{
    display: none;
    @media screen and (max-width:600px){
        display: unset;
        width: 100%;
        position: fixed;
        background-color: rgb(20, 19, 19);
        top: 0;
        left: 0;
        right: 0;
        height: 80px;
   
        
        display: flex;
        justify-content: center;
     //   align-items: center;
      
        overflow: hidden;
        z-index: 9;
    }
}


.mobile_layout{
    position: fixed;
    height: 100vh;
    width: 100vw;
    left:-100%;
    display: none;
   
   background-color: #000;
   z-index: 10;
   .mobile_link{
    display: flex;
    flex-direction: column;
   }
   .mobile_link li{
    color: #fff;
    font-family: "Satoshi-Medium";
    margin: 15px 10px;
    font-size: 22px;
    padding-left: 5vw;
    transform: translateX(-100%);
   }

   .mobil_icon{
    display: flex;
    width: 100%;
    margin-top: 8vw;
    justify-content: space-evenly;
    overflow: hidden;
   }

   #idICo{

    transform:translateY(100px) ;
   }

   @media (max-width:600px){
  //  display: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
   }

}


.plus_icon{
    display: none;
    @media screen and (max-width:600px){
        display: unset;
        position: fixed;
        right: 8%;
        bottom: 6%;
        padding: 10px 20px;
        background-color: #000;
        border-radius: 18px;
        z-index: 1000;
        color: #fff;
    

    }
  
}

.display_none{
    display: none;
}

.continuous-1 {
    display:inline-block;
    padding-bottom:5px;
    font-size: 17px;
    font-family: "Satoshi-Medium";
    cursor:pointer;
  // font-size:20px;
  background: 
      linear-gradient(currentColor 0 0) 
      var(--d, 201%) 100% /200% 1.5px 
      no-repeat;
  transition:0.8s;
}


.continuous_Black{
    color: rgb(240, 240, 240);
}

.continuous-1:hover {
  --d: -101%;

  
}

.header_bg{
    background-color: #000;
    background: rgba(0, 0, 0, 0);

}




.hero_text{
   
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    font-family: "Satoshi-Regular";
    text-align: center;
    color: $white;
    h1{
        z-index: 10;
        font-family: "Satoshi-Bold";
        
        font-size: 4vw;
        @media (max-width:600px){
            font-size: 6vw;
        }
        
    }
    h3{
        font-family: "Satoshi-Medium";
        z-index: 10;
        font-size: 1.3vw;
        letter-spacing: .5px;
        font-weight: thin;
        width: 100%;
        margin: 1.6vw 0;
        text-align: center;
     
        @media (max-width:600px){
            margin: 2vw 0;
            font-size: 12px !important;
        }
      
    }
}







