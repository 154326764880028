@import "base/variables";
@import "base/global";
@import "base/reset";
@import "base/typography";
@import "base/mixins";
@import '~video-react/styles/scss/video-react'; // or import scss

@import "components/loader";
@import "components/header";

@mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $lower-range: 420px, $upper-range: 900px) {
  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ((100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));

  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range) {
    font-size: $max-font-size;
  }
}

.home_sector_card:hover #imaf {
  transform: scale(1.07);
  filter: grayscale(0);
}

.container_base {
  max-width: 100%;
  width: 1200px;
  @media (max-width: 1000px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.container_base_team {
  max-width: 100%;
  width: 1200px;
  @media (max-width: 1000px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}



body::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}
body::-webkit-scrollbar-track {
  background: #f2f2f2; /* color of the tracking area */
}
body::-webkit-scrollbar-thumb {
  background-color: #222; /* color of the scroll thumb */
  /* roundness of the scroll thumb */
  border: 3px solid #222; /* creates padding around scroll thumb */
}

.button {
  cursor: pointer;

  border: 0;
  padding: 0.7rem 2rem;
  margin-top: 1vw;

  font-family: "Satoshi-Regular";
  position: relative;
  display: inline-block;
}

.button::before,
.button::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.button--hyperion {
  z-index: 5;
  font-family: "Satoshi-Regular";
  font-size: 1vw;
  padding: 0.7rem 2rem;
  border: 0;

  overflow: hidden;
  color: #fff;

  @media (max-width: 600px) {
    font-size: 2vw;
    padding: 0.3rem 1.6rem;
    margin-bottom: 8vw;
  }
}

.bottom_logo {
  position: absolute;
  left: 49%;
  transform: translate(-50%, -50%);
  bottom: 15%;

  display: flex;
  z-index: 3;

  svg {
    height: 70px;
    width: 150px;
  }

  @media (max-width: 1000px) {
    bottom: 25%;
    svg {
      height: 70px;
      width: 150px;
    }
  }

  @media (max-width: 600px) {
    bottom: 25%;
    svg {
      height: 70px;
      width: 120px;
    }
  }
}

.button--hyperion span {
  display: block;
  position: relative;
}

.button--hyperion > span {
  overflow: hidden;
}

.button--hyperion > span > span {
  overflow: hidden;
  mix-blend-mode: difference;
}

.button--hyperion:hover > span > span {
  animation: MoveUpInitial 0.2s forwards, MoveUpEnd 0.2s forwards 0.2s;
}

@keyframes MoveUpInitial {
  to {
    transform: translate3d(0, -105%, 0);
  }
}

@keyframes MoveUpEnd {
  from {
    transform: translate3d(0, 100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.button--hyperion::before {
  content: "";
  border-radius: 6px;
  background: #fff;
  transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
  transform-origin: 100% 50%;
}

.button--hyperion:hover::before {
  transform: scale3d(0, 1, 1);
  transform-origin: 0% 50%;
  border-radius: 6px;
}

.projects {
  display: flex;
  flex-flow: row wrap;
  max-width: 1400px;
  width:97%;
  //	overflow-x: hidden;
  margin: 0 auto;

  gap: 0px;

  justify-content: space-between;

  @media (max-width: 1000px) {
    gap: 16px;
    max-width: 1400px;
    width:100%;
  }
}

.hello{

  @media (max-width: 600px) {
    margin-top: 70px;
  }
}


.jjj{
  @media (max-width: 600px) {
   
    justify-content: center !important;
  }
}

.kkkk{
  @media (max-width: 600px) {
   
    width: 300px !important;
    height: 400px !important;
  
   
  }
}


.project_page_header{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: 40px;
  text-align: center;
  h1{
    font-size: 28px;
    margin-block: 10px;
    font-family: "Satoshi-bold";
    text-align: center;
  }
  p{
    font-size: 18px;
    font-family: "Satoshi-Regular";
    text-align: center;
    max-width: 900px;
    margin-block: 10px;

  }

}

.kskksks{
  @media (max-width: 600px) {
   
    width: 300px !important;
    height: 500px !important;
    margin-bottom: 0px !important;
   
  }
}

.ceo_Image_res {
  justify-content: center;
  @media (min-width: 800px) {
    justify-content: space-between;
  }
}

.footer__text {
  font-family: "Satoshi-Medium";
  h2 {
    font-size: 28px;
    margin-bottom: 8px;
  }
  span {
    font-size: 14px;
    font-weight: 300;
    margin: 2px 0;
  }

  @media (max-width: 600px) {
    h2 {
      font-size: 6vw;
    }
    span {
      font-size: 3vw;
    }
  }
}

.footer__text_Link {
  span {
    margin: 4px 0;
  }
}

.project_child {
  width: 100%;
  margin: 0 !important;
  
  
  height: 300px;
  overflow: hidden;
  position: relative;
  // filter: grayscale(1);
  transition: all 0.2s ease-in;

  @media (max-width: 500px) {
    height: 240px;
  }

  &:hover {
    // transform: scale(1.03);
    // z-index: 10;
    cursor: pointer;
  }


  img{
    object-fit: cover !important;
  }


  .project_child_name{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top:  0;
   
    display: flex;
    align-items: end;
    padding:20px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);

    h1{
      font-family: "Satoshi-Medium";
      color: white;
      font-size: 16px;
    }
  }
}

.project_child:hover {
  filter: grayscale(0);
}

.project_detail_name {
  h1 {
    font-family: "Satoshi-Bold";
  }

  h2 {
    font-family: "Satoshi-Medium";
    font-size: 1vw;
  }
}

.project_detail_sector {
  h1 {
    font-family: "Satoshi-Medium";
    padding: 10px;
  }
}
.project_detail_year {
  font-family: "Satoshi-Regular";
  font-size: 1.1vw;
}

.body_project_detail {
  height: 100vw;
  width: 90vw;
  margin: 0 auto;

  @media screen and (min-width: 980px) {
    height: 70%;
    width: 70%;
  }
}

.back_project_list {
  h1 {
    font-family: "Satoshi-Regular";
  }
}

.jsjsjsj{
  width: 28%;

  @media (max-width: 700px) {
    width: 100%;

  }
}

.image__text__only {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 0;
  padding: 10px;
  color: #fff;

  h2 {
    font-family: "Satoshi-Bold";
  }

  p {
    font-family: "Satoshi-Regular";
    font-size: 12px;
  }
}

.image__text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 0;
  background-color: #f00;
  height: 40px;
  opacity: 0.5;
  color: #fff;
  font-family: "Satoshi-Regular";
}

.wrapper {
  margin-top: 80px;
  position: relative;
  width: 220%;
  height: 300px;
  left: 50%;
  top: 40%;
  overflow: hidden;
}
.wrapper .box {
  width: 380px;
  height: 200px;
}
.box img {
  margin: 0 40px;
  width: 380px;
  height: 200px;
}

.team_image img {
  width: 200px;
  height: 250px;
  filter: grayscale(1);
}

.team_image {
  margin: 0 20px;
  width: 200px;
  height: 250px;
}

.continuous-3 {
  background: linear-gradient(90deg, currentColor calc(100% / 3), transparent 0 calc(200% / 3), currentColor 0) var(--d, 0%) 100% /300% 3px no-repeat;
  transition: 0.5s;
  display: inline-block;
  font-size: 40px;
  font-family: sans-serif;

  padding-bottom: 5px;
}
.continuous-3:hover {
  --d: 100%;
}

.our_approach_paragraph {
  font-family: "Satoshi-Medium";
  font-size: 1.2vw !important;
  margin-top: 8vw;
  :first-child {
    margin-bottom: 2vw;
  }
}

.our_services_paragraph {
  display: flex;
  margin-block: 5px;
  h1 {
    text-decoration: underline;
    font-family: "Satoshi-Bold";
    font-size: 27px;
    margin-right: 1vw;
    width: 40%;
    @media (max-width: 700px) {
      width: unset;
      margin-top: 20px;
      font-size: 24px;


    }

    margin-bottom: 12px;
  }

  p {
    font-family: "Satoshi-Medium";
    font-size: 15px;
    width: 70%;

    margin: auto 0;
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;


  }
}

.ceo_name {
  margin-top: 1vw;
  font-family: "Satoshi-Bold";
  font-size: 2vw;
}

.ceo_bio {
  margin-top: 0.4vw;
  font-family: "Satoshi-Regular";
  font-size: 1vw;
}

.Project_sectors {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  max-width: 800px;
  margin-bottom: 5vw;
  //width: 100%;

  h1 {
    font-family: "Satoshi-Medium";
    cursor: pointer;
    margin: 8px;
  }
}

.flag_project {
  height: 30vh;
}

.flag_project_main {
  height: 20vh;
}

.header_projects {
  font-family: "Satoshi-Medium";
  font-size: 2vw;

  @media (max-width: 600px) {
    font-size: 8vw;
    margin-bottom: 8vw;
  }
}

.thick-3 {
  display: inline-block;

  font-family: sans-serif;

  padding: 4px;
  cursor: pointer;
  background: linear-gradient(#000 0 0) 0% calc(-3px - 100%) / var(--d, 0%) 200% no-repeat;
  transition: 0.3s, background-size 0.3s 0.3s;
}
.thick-3:hover {
  --d: 100%;
  background-position: 50% 0%;
  color: #fff;
  transition: 0.3s, background-position 0.3s 0.3s, color 0.3s 0.3s;
}

.active_sector {
  --d: 100%;
  background-position: 50% 0%;
  color: #fff;
}

.page_link {
  font-family: "Satoshi-Medium";
  font-size: 16px;  
  cursor: pointer;
  padding: 12px;

  margin: 0 0.4vw;
}

.page_link_active {
  color: #f00;
  border: 1px solid #f00;
  padding: 12px;
  border-radius: 10px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next::after {
  color: rgba(255, 0, 0, 0.5);
}
.swiper-button-prev::after {
  color: rgba(255, 0, 0, 0.5);
}

.fraction {
  position: absolute;
  bottom: 5%;
  right: 10%;
  h1 {
    font-family: "Satoshi-Medium";
    font-size: 1.1vw;
    @media (max-width: 600px) {
      font-size: 4vw;
    }
  }
  @media (max-width: 600px) {
    right: 12%;
    bottom: 7%;
  }
}

.home_sector {
  //padding-top: 3vh;

  .home_sector_inner {
    overflow: hidden;
    width: 100%;
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .home_sector_card_image {
      position: relative;

      img {
        transition: all 0.4s ease-out;
      }
    }

    .sector_text {
      font-size: fluid-type(28px, 100px, 600px, 1000px);
    }

    .home_sector_card_text {
      display: flex;

      justify-content: space-between;
      align-items: flex-end;
      h1 {
        font-family: "Satoshi-Bold";
        font-size: 7vh;
      }
      h3 {
        font-family: "Satoshi-Medium";
        font-size: 5vh;
      }
    }

    .social_icon {
      svg {
        fill: #f00 !important;
      }
    }

    .home_sector_card {
      width: 49.8%;
      height: 270px;
      overflow: hidden;
      cursor: pointer;

      margin: 1px 0;
      @media (max-width: 600px) {
        width: 98%;
        height: 180px;
        margin: 2px auto;
      }
    }
  }

  @media (max-width: 600px) {
    padding-top: 2vh;
  }
}

.prhch {
  height: 300px !important;
}

@media screen and (min-width: 1200px) {
  .project_child {
    width: 33.3%;
    //  margin: 4px 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .project_child {
    width: 48%;
  }
}

.breadcrumb {
  width: fit-content;
  font-family: "Satoshi-Medium";
  background-color: #fff;
  padding: 10px 10px;
  border-radius: 8px;

  //	justify-content: flex-end;
  display: flex;
  // align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 680px) {
    padding: 10px 8px;

  }

  .active {
    color: $red;
   
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    margin: auto 8px;
    position: relative;
    //  transition: all 0.3s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $red;
      bottom: -20%;
      transform-origin: 100% 50%;
      transform: scale3d(0, 1, 1);
      transition: transform 0.3s;
    }

    &:hover {
      &::before {
        transform: scale3d(1, 1, 1);
      }
    }

    @media screen and (max-width: 680px) {
      margin: auto 2px;
  
    }


   
  }

  .breadcrumb_box {
    .Notactive {
      color: rgb(34, 34, 34);
      font-size: 15px;
      margin: auto 2px;
      cursor: pointer;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #333;
        bottom: 0;
        transform-origin: 100% 50%;
        transform: scale3d(0, 1, 1);
        transition: transform 0.3s;
      }

      &:hover {
        &::before {
          transform: scale3d(1, 1, 1);
        }
      }

      @media screen and (max-width: 680px) {
        margin: auto 2px;
    
      }
    }
  }
}

.likes__wrapper {
  background-color: #f00;
}
.likes__relavance {
  display: flex;
  align-items: flex-end !important;
  width: 0% !important;
}
.likes__list {
  position: absolute;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px !important;
  top: 70%;
  height: 160px !important;
  // background-color: red !important;

  z-index: 999;

  .likes__list__item {
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 80%;
    width: 60%;
    height: 80%;
    border-radius: 3px;
    background-color: #ffffff;
    padding: 8px;
    div {
      margin-top: 0px !important;
    }
  }
}

.team_title {
  @media (max-width: 600px) {
    margin-top: 20px;
  }
}

.career_Titile {
  font-size: 80px !important;
  font-family: "Satoshi-Medium" !important;
}

.team_top_container {
  height: 30vh;
  @media (max-width: 600px) {
    height: 50vh;
  }
}

.team_second_first_paragraph {
  max-width: 650px;

  @media (max-width: 600px) {
    margin-top: 20px;
    max-width: 300px;
  }
}

.team_third_container {
}

.team_fourth_image {
  margin-top: 60px !important;

  @media (max-width: 600px) {
    margin-top: 30px !important;
  }
}

.sangar_Aree {
  width: 36%;

  @media (max-width: 600px) {
    width: 90%;
  }
}

.team_second_second_paragraph {
  max-width: 650px;
  @media (max-width: 600px) {
    margin: 20px 0 30px 0;
    max-width: 300px;
  }
}

.team_title_paragraph {
  @media (max-width: 600px) {
    margin-top: 20px;
  }
}

.cv_career {
  h3 {
    margin: 50px 0;
    font-family: "Satoshi-Medium";
    width: 400px !important;
    font-size: 17px;
    @media (max-width: 600px) {
      width: 300px !important;
      font-size: 18px;

    }

  }

  a {
    font-family: "Satoshi-Medium";
    font-size: 18px;
    text-decoration: underline;

  }
}

.carrer_image{
  @media (max-width: 800px) {
   display: none;

  }
}


.team_about{
 

display: flex;
  .team_about_p{
    font-size: 18px !important;
   
    
   
    font-family: "Satoshi-Medium";


    a{
      text-decoration: underline;
    }
  
  }

  .team_about_p1{
    font-size: 28px !important;
   // max-width: 600px !important;
 


    font-family: "Satoshi-Medium";
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    a{
      text-decoration: underline;
      font-size: 18px !important;
      @media (max-width: 800px) {
        margin: 10px 0;
     
    
     
       }

    }


    @media (max-width: 800px) {
      margin: 30px 0;
      max-width: 300px;
  
   
     }
  
  }


  @media (max-width: 800px) {
    flex-direction: column;

 
   }
  
  


}


.img_abou_first{

  img{


  @media (min-width: 800px) {
    height: 80vh;
 
   }  }
}


.hThree{
  font-size: 18px;
  @media (min-width: 800px) {
    font-size: 30px;
 
   }  

}

.teamSecond_Shot{
  @media (min-width: 800px) {
    margin-left: 10px;
 
   }  

   @media (max-width: 800px) {
    margin-top: 80px
 
   }  

}


.teamSecond_Shot_two{
  @media (min-width: 800px) {
    margin-right: 10px;
 
   }  

   @media (max-width: 800px) {
    margin-top: 80px
 
   }  

}

.ffff{
  @media (min-width: 800px) {
    display: none;
 
   }  
}

.main_section_team{
  display: flex;

  h1{
    font-size: 20px;
    margin-bottom: 4px;
  }
  p{
    font-size: 14px;
    color: #999;
  }
  justify-content: space-between;
  font-family: "Satoshi-Medium";
  flex-wrap: wrap;
  @media (max-width: 800px) {
    width: 100%;
    justify-content: center;
    margin: 20px 0;
 
   } 
  .first_section{
    display: flex;
    flex-direction: column;
    @media (max-width: 800px) {
   
      margin: 20px 0;
   
     } 
    .first_section_image{
      width: 200px;
      height: 300px;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .second_section{
    display: flex;
 
    width: 40%;
   
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 800px) {
      width: 100%;
      justify-content: space-evenly;
    

   
     }  

  
      .second_second_section{
        display: flex;
        flex-direction: column;
        @media (max-width: 800px) {
           margin: 20px 0;
        
       
         } 
        .first_section_image{
          width: 200px;
          
          height: 300px;
          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .second_third_section{
        display: flex;
        flex-direction: column;
        @media (max-width: 800px) {
   
          margin: 20px 0;
       
         } 
        .first_section_image{
          width: 200px;
          height: 300px;
          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

      }
    }

  
   
}